/** @jsx jsx */
import { Box, Flex, Heading, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useState, useRef } from 'react'
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5'

const DragToSpin = ({ sequence, resistance }) => {
  const timeoutRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [dragStart, setDragStart] = useState(0)

  if (!sequence || !sequence.length) return null

  const sequenceLength = sequence.length

  const handleDrag = ({ clientX, buttons }) => {
    // Only fire if "dragging"
    if (!buttons) return

    const dx = clientX - dragStart
    const direction = dx < 0 ? -1 : 1
    const absDx = Math.abs(dx)

    if (absDx < resistance) return

    setDragStart(clientX)

    const nextActive = activeIndex + 1 * direction
    setActiveIndex(
      (nextActive < 0 ? sequenceLength - 1 : nextActive) % sequenceLength
    )
  }

  return (
    <Box
      onPointerDown={({ clientX }) => {
        setDragStart(clientX)
        clearTimeout(timeoutRef.current)
      }}
      onPointerMoveCapture={handleDrag}
      onPointerUp={() => {
        timeoutRef.current = setTimeout(() => {
          setDragStart(0)
        }, 6000)
      }}
      sx={{
        position: 'relative',
        height: '100%'
      }}
    >
      {/* Set the dimensions for the container */}
      <GatsbyImage
        image={sequence.gatsbyImageData}
        alt={sequence[0].description ?? sequence[0].title}
        sx={{ height: '100%', opacity: 0 }}
      />

      {sequence.map((img, i) => (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: i === activeIndex ? 1 : 0
          }}
          key={i}
        >
          <GatsbyImage
            image={img.gatsbyImageData}
            alt={img.description ?? img.title}
            sx={{ height: '100%' }}
          />
        </Box>
      ))}

      <Flex
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
          backgroundColor: 'black70',
          cursor: 'grab',
          zIndex: 1,
          opacity: dragStart ? 0 : 1,
          transition: 'opacity 0.3s'
        }}
      >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <IoChevronBackSharp />

          <Heading
            as="h3"
            variant="h2"
            sx={{ padding: '0 2.4rem', userSelect: 'none' }}
          >
            Drag to
            <br />
            Spin
          </Heading>

          <IoChevronForwardSharp />
        </Flex>
      </Flex>
    </Box>
  )
}

DragToSpin.propTypes = {
  sequence: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      fluid: PropTypes.object.isRequired
    })
  ),
  resistance: PropTypes.number
}

DragToSpin.defaultProps = {
  resistance: 20
}

export default DragToSpin
