/** @jsx jsx */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, Flex, Card, Text, jsx } from 'theme-ui'
import { useIntl } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import formatMoney from '~/utils/formatMoney'
import ProductLink from '~/components/Product/Link'
import QuickProductAddToCartButton from '~/components/Product/AddToCartButton/Quick'
import useProductVariants from '~/hooks/components/use-product-variants'
import { Fragment, useEffect, useState } from 'react'
import ImgLoader from '~/components/Generic/ImgLoader'
import useVariantAvailability from '~/hooks/components/use-variant-availability'
import useAllVariants from '~/hooks/components/use-all-variants'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const QuickAddProductCard = ({ lineup, product, collectionName }) => {
  const { locale } = useIntl()
  const translate = useTranslate()
  const { currentVariant, selectVariantWithOptions, selectVariantWithSKU } =
    useProductVariants(product.variants)
  const [apiError, setApiError] = useState(null)
  const {
    product: shopifyProduct,
    isFetchingAvailability,
    isAvailable,
    error: availabilityError
  } = useVariantAvailability({
    variantId: currentVariant.shopifyId
  })

  const productIsAvailable = shopifyProduct
    ? shopifyProduct.availableForSale
    : true

  const { product: productWithAllVariants } = useAllVariants(product.slug)

  useEffect(() => {
    if (productWithAllVariants && productWithAllVariants.variants) {
      const allVariants = [...productWithAllVariants.variants.edges].map(
        edge => edge.node
      )
      // Check if the currently selected variant is available for sale
      const currentShopifyVariant = allVariants.find(
        v => (v.sku = currentVariant.sku)
      )
      const currentVariantAvailable =
        currentShopifyVariant.availableForSale ||
        currentShopifyVariant.currentlyNotInStock
      // If not available for sale, find the first variant that is and set it as the current variant
      if (!currentVariantAvailable) {
        const firstAvailableVariant = allVariants.find(
          v => v.availableForSale || v.currentlyNotInStock
        )
        firstAvailableVariant && selectVariantWithSKU(firstAvailableVariant.sku)
      }
    }
  }, [productWithAllVariants])

  if (!product.variants || !product.variants.length) return null

  const quickAddOptions = product.variants
    // 1. Get all options across variants
    .reduce((acc, variant) => {
      if (!variant.optionValues) return acc

      return [...acc, ...variant.optionValues]
    }, [])
    // 2. Remove duplicates
    .reverse()
    .filter(
      (option, index, options) =>
        options.slice(index + 1).find(o => o.slug === option.slug) === undefined
    )
    .reverse()
    // 3. Remove unknown options
    .filter(option => option.option_type)
    // 4. Group them for easy access
    .reduce(
      (acc, option) => {
        const [optionType] = option.option_type
        const optionName = optionType?.slug || null
        if (optionName) {
          acc[optionName] = acc[optionName]
            ? [...acc[optionName], option]
            : [option]
        }
        return acc
      },
      { color: [], size: [], values: [] }
    )

  const enableHoverImage =
    (product.productType?.slug !== 'apparel' &&
      product.images &&
      product.images[1]) ||
    (product.productType?.slug === 'apparel' &&
      currentVariant.images &&
      currentVariant.images[0])

  return (
    !(!productIsAvailable && product.hideOutOfStock) && (
      <Card
        sx={{
          position: 'relative',

          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          padding: 0,
          '&:hover': {
            '.hiddenOnHover': {
              opacity: [null, 0]
            },
            '.visibleOnHover': {
              opacity: [null, 1]
            }
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'block',
            marginBottom: ['8px', '14px', '14px', '19px', '19px']
          }}
        >
          {!productIsAvailable && (
            <div
              sx={{
                position: 'absolute',
                width: '100%',
                height: ['44px', '75px !important'],
                bottom: '0',
                left: 0,
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none'
              }}
            >
              <div
                sx={{
                  variant: 'buttons.primary',
                  width: '100%',
                  height: ['44px', '75px !important'],
                  lineHeight: ['44px', '75px !important'],
                  fontFamily: 'primary',
                  fontSize: [2, '2rem !important'],
                  textAlign: 'center',
                  letterSpacing: '1px',
                  color: 'black',
                  borderColor: 'borderInactive',
                  borderRadius: 0,
                  backgroundColor: 'white'
                }}
              >
                {translate('product.sold_out')}
              </div>
            </div>
          )}
          <ProductLink
            product={product}
            lineup={lineup}
            collectionName={collectionName}
            from="product image"
            variant={currentVariant}
            sx={{
              ':hover': {
                '& > div:first-of-type': {
                  opacity: [null, null, enableHoverImage ? 0 : 1]
                },
                '& > div:last-of-type': {
                  opacity: [null, null, enableHoverImage ? 1 : null],
                  visibility: [null, null, enableHoverImage ? 'visible' : null]
                }
              }
            }}
          >
            <ImgLoader
              image={currentVariant.mainImage}
              alt={
                currentVariant.mainImage.description ??
                currentVariant.mainImage.title
              }
              forwardSx={{
                transition: '0.25s ease'
              }}
              imgStyle={{ objectFit: 'cover', objectPosition: '50% 0' }}
            />
            {product.productType?.slug !== 'apparel' &&
              product.images &&
              product.images[1] && (
                <GatsbyImage
                  image={product.images[1].gatsbyImageData}
                  alt={
                    currentVariant.mainImage?.description ??
                    currentVariant.mainImage?.title
                  }
                  sx={{
                    position: 'absolute !important',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    visibility: 'hidden',
                    transition: '0.25s ease'
                  }}
                  imgStyle={{ objectFit: 'cover', objectPosition: '50% 0' }}
                />
              )}
            {product.productType?.slug === 'apparel' &&
              currentVariant.images &&
              currentVariant.images[0] && (
                <GatsbyImage
                  image={currentVariant.images[0].gatsbyImageData}
                  alt={currentVariant.mainImage.title}
                  sx={{
                    position: 'absolute !important',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    visibility: 'hidden',
                    transition: '0.25s ease'
                  }}
                  imgStyle={{ objectFit: 'cover', objectPosition: '50% 0' }}
                />
              )}
          </ProductLink>
          <Box>
            <QuickProductAddToCartButton
              setApiError={setApiError}
              price={currentVariant.price}
              quantity={1}
              regularPrice={currentVariant.regularPrice}
              sku={currentVariant.sku}
              variantId={currentVariant.shopifyId}
              isFetchingAvailability={isFetchingAvailability}
              isAvailable={isAvailable}
              availabilityError={availabilityError}
              limitOnePerCustomer={currentVariant.limitOnePerCustomer}
              externalLink={product.externalProductPageUrl}
              comingSoon={currentVariant.comingSoon}
            />
          </Box>
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            opacity: 1,
            fontSize: [1, 3],
            height: '100%',
            transition: 'opacity 0.25s ease'
          }}
        >
          <ProductLink
            lineup={lineup}
            product={product}
            collectionName={collectionName}
            price={currentVariant.price}
            from={'name text'}
            sx={{
              fontSize: [2, 2, null, 5],
              lineHeight: '115%',
              textAlign: 'center',
              mb: '8px'
            }}
          >
            <Box as="span" sx={{ display: 'block', mb: '8px' }}>
              {product.name}
            </Box>
            {currentVariant.regularPrice !== currentVariant.price ? (
              <Fragment>
                <span
                  sx={{
                    textDecoration: 'line-through',
                    mr: '10px',
                    fontWeight: 'light'
                  }}
                >
                  {formatMoney(currentVariant.regularPrice, { locale })}
                </span>
                <span sx={{ color: 'red', fontWeight: 'light' }}>
                  {formatMoney(currentVariant.price, { locale })}
                </span>
              </Fragment>
            ) : (
              <span sx={{ fontWeight: 'light' }}>
                {formatMoney(currentVariant.price, { locale })}
              </span>
            )}
          </ProductLink>
          {/* Color option selectors*/}
          {quickAddOptions.color.length > 0 && (
            <Flex
              sx={{
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              {quickAddOptions.color.map((option, index) => (
                <Flex
                  sx={{ padding: '0.4rem' }}
                  key={`mobile-${index}-${option.slug}`}
                >
                  <input
                    type="radio"
                    name="color"
                    value={option.slug}
                    data-type="color"
                    onChange={({ target: { checked } }) => {
                      if (checked) selectVariantWithOptions(option)
                    }}
                    sx={{
                      clipPath: 'inset(100%)',
                      clip: 'rect(1px, 1px, 1px, 1px)',
                      width: '1px',
                      height: '1px',
                      overflow: 'hidden',
                      position: 'absolute',
                      whiteSpace: 'nowrap',
                      ':checked + label': {
                        color: 'white',
                        border: '1px solid rgba(0,0,0,0.7)',
                        boxShadow: '0 0 7px rgba(0,0,0,0.6)'
                      }
                    }}
                    id={`${collectionName}-${product.slug}-${option.slug}`}
                  />

                  <Flex
                    as="label"
                    htmlFor={`${collectionName}-${product.slug}-${option.slug}`}
                    sx={{
                      position: 'relative',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '2rem',
                      height: '2rem',
                      padding: 0,
                      fontSize: 4,
                      backgroundColor: option.optionalRendering,
                      border: '1px solid rgba(0,0,0,0.2)',
                      borderRadius: '50%',
                      mx: 'auto',
                      cursor: 'pointer'
                    }}
                    title={option.name}
                    aria-label={`${option.name}`}
                  ></Flex>
                </Flex>
              ))}
            </Flex>
          )}
          {apiError && (
            <Text
              color="errorDark"
              variant="textLink"
              mt="1.5rem"
              sx={{ textAlign: 'center' }}
            >
              {apiError.toString()}
            </Text>
          )}
        </Flex>
      </Card>
    )
  )
}

export default QuickAddProductCard
