const filterDuplicates = (arr, key = 'id') =>
  arr
    .reverse()
    .filter(
      (item, index, arr) =>
        arr.slice(index + 1).find(i => i[key] === item[key]) === undefined
    )
    .reverse()

export default filterDuplicates
