/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Box, Grid, jsx } from 'theme-ui'
import RelatedProducts from '~/components/Generic/RelatedProducts'
import ProductSpecifications from '~/components/Product/Specifications'
import ProductPageHeader from './Header'
import ContentSection from '~/components/Content/Section'
import ProductReviews from '~/components/Product/Reviews'
import { GatsbyImage } from 'gatsby-plugin-image'
import useProductReviews from '~/hooks/components/use-product-reviews'
import { ScrollSnaps } from '~/components/Generic/ScrollSnap'
import { useEffect } from 'react'
import useProductId from '~/hooks/components/use-product-id'
import { useLocation } from '@reach/router'
import aa from 'search-insights'
import BikeContent from './BikeContent'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import {
  changeVariant,
  getVariantFromQueryParams
} from '~/utils/variantQueryStrings'

const ProductPage = ({ location, product }) => {
  let userToken = ''
  const { trackProductViewed } = useAnalytics()

  aa('getUserToken', null, (err, algoliaUserToken) => {
    if (err) {
      console.error(err)
      return
    }
    userToken = algoliaUserToken
  })
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const objectID = params.get('objectID')
  const queryID = params.get('queryID')

  // each product requires at least one variant (enforced by our Contentful model)

  const [variant, setVariant] = useState(product.variants[0])
  const variantId = String(product.variants[0].shopifyId)
  const { productId } = useProductId({ variantId })

  const productType = product.productType?.slug ?? ''

  useEffect(() => {
    document.documentElement.classList.add('no-scroll')
    return () => document.documentElement.classList.remove('no-scroll')
  }, [])

  useProductReviews()

  useEffect(() => {
    if (variant.optionValues) {
      setVariant(getVariantFromQueryParams(product.variants, params))
    }

    trackProductViewed({ productHandle: product.slug })
  }, [])

  const bikeContentProps = {
    objectID,
    queryID,
    product,
    variant,
    changeVariant,
    setVariant
  }
  return variant ? (
    <Fragment>
      {productType !== 'electric-motorbike' && (
        <ProductPageHeader
          search={{ objectID, queryID }}
          product={product}
          activeVariant={variant.id}
          onVariantChange={changeVariant}
          setVariant={setVariant}
        />
      )}

      {productType === 'electric-motorbike' &&
        (product.scrollSnapSections ? (
          <ScrollSnaps>
            <BikeContent {...bikeContentProps} />
          </ScrollSnaps>
        ) : (
          <BikeContent {...bikeContentProps} />
        ))}

      <Grid
        columns={1}
        sx={{
          gap:
            productType === 'electric-motorbike'
              ? 0
              : ['2.8rem', '0.9rem 0', '2rem 0', '3.7rem 0'],
          px: [0, productType === 'electric-motorbike' ? null : '3.5rem', null]
        }}
      >
        {product.sections?.map(section => (
          <ContentSection section={section} key={section.id} />
        ))}
      </Grid>

      {product.exclusive && (
        <Grid
          columns={2}
          gap={['5px', '8px', '15px 18px']}
          sx={{
            width: '100%',
            px: [0, '3.5rem', '4rem'],
            margin: 'auto',
            maxWidth: 'calc(1736px + 8rem)',
            mt: '-62px'
          }}
        >
          {product.images?.map(img => (
            <Box
              sx={{
                width: '100%',
                '.gatsby-image-wrapper > div': { pb: '100%' }
              }}
            >
              <GatsbyImage
                alt={img?.description ?? img?.title}
                image={img.gatsbyImageData}
                sx={{ width: '100%' }}
              />
            </Box>
          ))}
        </Grid>
      )}

      <ProductSpecifications specs={product.specs} />

      {product.relatedProducts && (
        <RelatedProducts products={product.relatedProducts} />
      )}

      <ProductReviews shopifyProductId={productId} />
    </Fragment>
  ) : null
}

export default ProductPage
