/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import QuantitySelector from '~/components/Generic/QuantitySelector'
import { Box } from 'theme-ui'
import { useState } from 'react'
import ProductOptionSelector from '~/components/Product/OptionSelector/alternate'
import { Flex } from 'theme-ui'
import AlternateProductAddToCartButton from '~/components/Product/AddToCartButton/alternate'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { navigate } from '@reach/router'
import { Button } from '@theme-ui/components'
import aa from 'search-insights'

const ProductAddToCart = ({
  notices = false,
  productName,
  variants,
  activeVariant,
  setVariant,
  optionValues,
  onVariantChange,
  externalLink = false,
  search,
  forwardSx,
  product
}) => {
  let userToken = ''
  aa('getUserToken', null, (err, algoliaUserToken) => {
    if (err) {
      console.error(err)
      return
    }

    userToken = algoliaUserToken
  })
  const translate = useTranslate()
  const { cart, addToCart } = useCart()
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const variant = variants.find(v => v.id === activeVariant)

  const options = optionValues
    .filter(
      (option, index, options) =>
        options.slice(index + 1).find(o => o.slug === option.slug) === undefined
    )
    // 3. Remove unknown options
    .filter(option => option.option_type)
    // 4. Group them for easy access
    .reduce(
      (acc, option) => {
        const [optionType] = option.option_type
        const optionName = optionType?.slug || null
        if (optionName) {
          acc[optionName] = acc[optionName]
            ? [...acc[optionName], option]
            : [option]
        }
        return acc
      },
      { color: [], size: [] }
    )
  const { color: colors, size: sizes } = options

  const quantityOptions = Array.from({
    length: variant.limitOnePerCustomer ? 1 : 20
  }).map((_, i) => ({
    name: String(i + 1),
    value: i + 1
  }))

  const handleSubmit = async event => {
    if (event) event.preventDefault()

    let variantInCart = cart.data.lineItems.some(
      product => product.variant.sku === variant.sku
    )

    if (variant.limitOnePerCustomer && variantInCart) {
      setApiError('Limit of 1 item per customer')
    } else {
      setIsLoading(true)
      setApiError(null)

      try {
        await addToCart(variant.shopifyId, quantity)

        if (search?.objectID && search?.queryID) {
          aa('convertedObjectIDsAfterSearch', {
            index: 'products',
            eventName: 'Product added to cart',
            userToken,
            objectIDs: [search.objectID],
            queryID: search.queryID
          })
        }
        navigate('/cart')
      } catch (error) {
        setApiError(translate('error.api.default'))
      }

      setIsLoading(false)
    }
  }

  return externalLink ? (
    <Button
      as="a"
      href={externalLink}
      target="__blank"
      sx={{
        '--border-color': 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '32.2rem',
        fontSize: ['1.2rem', '1.3rem', null, null, '1.5rem'],
        fontWeight: 'bold',
        height: ['3.8rem', '4rem', '3.2rem', '4.4rem'],
        my: 4
      }}
    >
      ORDER NOW
    </Button>
  ) : (
    <Fragment>
      <form
        name={`${productName}-add-to-cart`}
        onSubmit={handleSubmit}
        sx={{
          p: [
            notices ? '4rem 0 0rem' : '4rem 0 6rem',
            null,
            notices ? '3rem 0 0rem' : '3rem 0 3rem'
          ],
          fieldset: {
            position: 'relative',
            padding: 0,
            margin: 0,
            border: 0
          },
          legend: {
            variant: 'text.h5',
            textTransform: 'uppercase',
            fontSize: [2, null, null, 3],
            mb: '0.6rem',
            whiteSpace: 'nowrap'
          },
          ...forwardSx
        }}
      >
        <Flex
          sx={{
            display: Object.keys(options).some(key => options[key].length > 0)
              ? 'flex'
              : 'none',
            flexFlow: ['row wrap', null, 'column nowrap'],
            mb: ['3.3rem', null, '2.5rem'],
            'fieldset:not(:last-of-type)': { mr: '8rem', mb: '2.5rem' }
          }}
        >
          {optionValues && colors?.length ? (
            <fieldset>
              <legend>
                {
                  variant.optionValues.filter(
                    value => value.option_type[0].slug === 'color'
                  )[0].name
                }
              </legend>

              <ProductOptionSelector
                type="color"
                options={colors}
                selectedOptions={variant.optionValues}
                handleSelection={option =>
                  onVariantChange(variant, option, setVariant, product)
                }
              />
            </fieldset>
          ) : null}

          {optionValues && sizes?.length ? (
            <fieldset>
              <legend>Size</legend>

              <ProductOptionSelector
                type="size"
                options={sizes}
                selectedOptions={variant.optionValues}
                handleSelection={option =>
                  onVariantChange(variant, option, setVariant, product)
                }
              />
            </fieldset>
          ) : null}
          {/* Options that aren't colors or sizes */}
          {Object.keys(options)
            .filter(key => key !== 'color' && key !== 'size')
            .map(key => {
              const option = options[key]
              return (
                <fieldset>
                  <legend>{option[0].option_type[0].name}</legend>

                  <ProductOptionSelector
                    type={key}
                    options={option}
                    selectedOptions={variant.optionValues}
                    handleSelection={option =>
                      onVariantChange(variant, option, setVariant, product)
                    }
                  />
                </fieldset>
              )
            })}
        </Flex>

        <Flex
          sx={{
            flexFlow: ['row nowrap', null, 'column nowrap'],
            alignItems: ['flex-end', null, 'flex-start']
          }}
        >
          {optionValues && quantityOptions ? (
            <fieldset
              sx={{
                mr: ['3.5rem !important', null, 0],
                mb: [null, null, '2.8rem !important']
              }}
            >
              <legend>Quantity</legend>

              <Box sx={{ maxWidth: ['4.5rem', '5.5rem', '6.5rem'] }}>
                <QuantitySelector
                  handleChange={value => setQuantity(Number(value))}
                  options={quantityOptions}
                />
              </Box>
            </fieldset>
          ) : null}

          <AlternateProductAddToCartButton
            isLoading={isLoading}
            error={apiError}
            variantId={variant.shopifyId}
            comingSoon={variant.comingSoon}
          />
        </Flex>
      </form>
    </Fragment>
  )
}

ProductAddToCart.propTypes = {
  productName: PropTypes.string,
  optionValues: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      presentation: PropTypes.string.isRequired,
      option_type: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired
        })
      )
    })
  ),
  activeVariant: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sku: PropTypes.string,
      price: PropTypes.number.isRequired,
      regularPrice: PropTypes.number.isRequired,
      optionValues: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          title: PropTypes.string,
          presentation: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  onVariantChange: PropTypes.func.isRequired
}

export default ProductAddToCart
