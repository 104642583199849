import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductPage from '~/components/Product/Page'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

const ProductTemplate = ({ location, data: { product } }) => {
  const { pathname } = useLocation()

  const productType = product.productType?.slug ?? ''

  return (
    <Layout
      showBreadcrumbs={productType !== 'electric-motorbike'}
      breadcrumb={{
        crumbLabel: product.name
      }}
    >
      <Helmet>
        <link
          rel="canonical"
          key={`https://super73.com${pathname}`}
          href={`https://super73.com${pathname}`}
          data-react-helmet="true"
        />
      </Helmet>
      <Metadata
        title={product.metaTitle || product.name}
        description={
          product.metaDescription?.metaDescription || product.shortDescription
        }
      />
      <ProductPage location={location} product={product} />
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
  }
`

export default ProductTemplate
