import { useEffect } from 'react'
import { logSentryError } from '@chordcommerce/gatsby-theme-performance/src/services/sentry'

/*
 * See gatbsy-browser.js for the Yotpo widget script.
 */

const useProductReviews = () => {
  useEffect(() => {
    // Scan the page for Yotpo widgets.
    try {
      if (!window.yotpo) return
      const api = new window.Yotpo.API(window.yotpo)
      api.refreshWidgets()
    } catch (error) {
      logSentryError(error, { source: 'useProductReviews' })
    }
  }, [])
}

export default useProductReviews
