/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState, Fragment } from 'react'
import { Flex, Button, Text, Spinner, jsx } from 'theme-ui'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'

import PlusIconBigBold from '~/assets/images/icons/plus-icon-big-bold.svg'
import { navigate } from '@reach/router'

const QuickProductAddToCartButton = ({
  price,
  quantity,
  regularPrice,
  sku,
  handleAddToCart,
  limitOnePerCustomer,
  variantId,
  isFetchingAvailability,
  isAvailable,
  availabilityError,
  setApiError,
  externalLink,
  customCtaText,
  comingSoon,
  ...props
}) => {
  const translate = useTranslate()
  const { cart, addToCart } = useCart()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const handleSubmit = async event => {
    if (event) event.preventDefault()
    if (buttonIsLoading || isFetchingAvailability || !isAvailable) return

    let variantInCart = cart.data.lineItems.some(
      product => product.variant.sku === sku
    )

    if (limitOnePerCustomer && variantInCart) {
      setApiError('Limit of 1 item per customer')
    } else {
      setButtonIsLoading(true)
      setApiError(null)

      try {
        await addToCart(variantId, quantity)
        navigate('/cart')
      } catch (error) {
        setApiError(translate('error.api.default'))
      }

      setButtonIsLoading(false)
    }
  }

  return externalLink ? (
    <div
      className="visibleOnHover"
      sx={{
        position: 'relative',
        width: '100%',
        height: ['50px', '60px', '75px'],
        marginTop: ['-50px', '-60px', '-75px'],
        zIndex: 1,
        opacity: 0,
        backgroundColor: 'transparent',
        transition: 'opacity 0.25s ease',
        pointerEvents: ['none', 'auto'],
        visibility: ['hidden', 'visible']
      }}
    >
      <Button
        as="a"
        href={externalLink}
        target="__blank"
        className="visibleOnHover"
        variant="secondary"
        {...props}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100% !important',
          fontFamily: 'primary',
          fontSize: [2, 5],
          letterSpacing: '1px',
          borderColor: 'borderInactive',
          borderRadius: 0,
          backgroundColor: 'white',
          ':hover': { backgroundColor: 'white' }
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: customCtaText && 'center',
            height: '100%',
            width: '100%',
            paddingLeft: customCtaText ? 0 : ['25px'],
            color: 'black !important',
            '& > svg': { marginRight: '11px' }
          }}
        >
          {!customCtaText && <PlusIconBigBold />}
          <Text
            sx={{
              textAlign: customCtaText && 'center'
            }}
          >
            {customCtaText ? customCtaText : translate('product.add_to_cart')}
          </Text>
        </Flex>
      </Button>
    </div>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="visibleOnHover"
      sx={{
        position: 'relative',
        width: '100%',
        height: ['50px', '60px', '75px'],
        marginTop: ['-50px', '-60px', '-75px'],
        zIndex: 1,
        opacity: 0,
        backgroundColor: 'transparent',
        transition: 'opacity 0.25s ease',
        pointerEvents: ['none', 'auto'],
        visibility: ['hidden', 'visible']
      }}
    >
      <Button
        className="visibleOnHover"
        variant="secondary"
        disabled={
          buttonIsLoading ||
          isFetchingAvailability ||
          !isAvailable ||
          comingSoon
        }
        {...props}
        p={0}
        type="submit"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100% !important',
          fontFamily: 'primary',
          fontSize: [2, 5],
          letterSpacing: '1px',
          color: 'black',
          borderColor: 'borderInactive',
          borderRadius: 0,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
            color: 'black !important'
          }
        }}
      >
        {(buttonIsLoading || isFetchingAvailability) && (
          <Spinner
            aria-label="Loading..."
            data-testid="spinner"
            size="15"
            color="inherit"
          />
        )}
        {comingSoon &&
          !isFetchingAvailability &&
          !buttonIsLoading &&
          translate('product.coming_soon')}
        {!buttonIsLoading && !isFetchingAvailability && !comingSoon && (
          <Fragment>
            {!isAvailable && translate('product.sold_out')}
            {isAvailable && (
              <Flex
                sx={{
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  paddingLeft: ['25px'],
                  '& > svg': { marginRight: '11px' }
                }}
              >
                <PlusIconBigBold />
                <Text>{translate('product.add_to_cart')}</Text>
              </Flex>
            )}
          </Fragment>
        )}
      </Button>

      {availabilityError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {availabilityError.toString()}
        </Text>
      )}
    </form>
  )
}

QuickProductAddToCartButton.propTypes = {
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
  variantId: PropTypes.number.isRequired,
  label: PropTypes.string
}

QuickProductAddToCartButton.defaultProps = {
  label: 'QUICK ADD'
}

export default QuickProductAddToCartButton
