import useShopify from '~/hooks/shopify/use-shopify'
import { decodeShopifyID, encodeShopifyVariantID } from '~/utils/shopify'

const useProductId = ({ variantId }) => {
  const query = `
    {
      node(id: "${encodeShopifyVariantID(variantId)}") {
        ... on ProductVariant {
          product {
            id
          }
        }
      }
    }
  `

  const { data, isLoading, error } = useShopify(query)

  return {
    productId: data?.node?.product?.id
      ? decodeShopifyID(data.node.product.id)
      : null,
    isFetchingProductId: isLoading,
    error: error
  }
}

export default useProductId
