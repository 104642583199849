/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { IoAddSharp } from 'react-icons/io5'
import { useState } from 'react'

const ProductDetailedInformation = ({
  product: { features, fabricCareInstructions, needHelp, shippingInfo }
}) => {
  const details = [
    { ...features, title: 'Product Features' },
    { ...fabricCareInstructions, title: 'Fabric & Care Instructions' },
    { ...needHelp, title: 'Need Help' },
    { ...shippingInfo, title: 'Shipping Info' }
  ].filter(section => section.childMarkdownRemark)

  const [openedIndexes, setOpenedIndexes] = useState([])

  if (!details.length) return null

  return details.map((section, i) => (
    <details
      onToggle={e =>
        e.target.open
          ? setOpenedIndexes([...openedIndexes, i])
          : setOpenedIndexes(openedIndexes.filter(index => index !== i))
      }
      key={section.id}
      sx={{
        padding: '3.2rem 0',
        borderTop: theme => `1px solid ${theme.colors.borderInactive}`,
        ':last-of-type': {
          borderBottom: theme => `1px solid ${theme.colors.borderInactive}`
        }
      }}
    >
      <Flex
        dir="row"
        as="summary"
        sx={{
          alignItems: 'center',
          textTransform: 'uppercase',
          fontSize: [2, null, 3],
          cursor: 'pointer',
          '::marker': { content: '""' },
          '::-webkit-details-marker': { opacity: 0 }
        }}
      >
        <IoAddSharp
          sx={{
            marginRight: 3,
            transform: openedIndexes.includes(i) ? 'rotate(45deg)' : null
          }}
        />

        {section.title}
      </Flex>

      <div
        dangerouslySetInnerHTML={{
          __html: section.childMarkdownRemark.html
        }}
        sx={{
          fontSize: [2, null, 3],
          paddingLeft: '3.2rem',
          a: { variant: 'text.link' }
        }}
      />
    </details>
  ))
}

export default ProductDetailedInformation
