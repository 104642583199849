/** @jsx jsx */
import { jsx, Flex, Box, Heading, Text, Container } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import formatMoney from '~/utils/formatMoney'
import ProductDetailedInformation from '~/components/Product/DetailedInformation'
import { useIntl } from 'gatsby-plugin-intl'
import ProductAddToCart from '~/components/Product/AddToCart'
import { Fragment, useEffect } from 'react'

const AccessoryHeader = ({
  product,
  search,
  activeVariant,
  onVariantChange,
  setVariant,
  primaryImage,
  secondaryImages,
  optionValues
}) => {
  const variant = product.variants.find(v => v.id === activeVariant)

  const { locale } = useIntl()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }, [variant])

  return (
    <Container
      as="header"
      variant="fullWidth"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 26rem', '1fr 46rem'],
        gap: ['2.4rem 0', '7rem 0', '0 3rem', '0 6rem'],
        px: [null, null, 'var(--horizontal-inset)']
      }}
    >
      <ImageCarousel
        enableZoom
        primaryImage={primaryImage}
        secondaryImages={secondaryImages}
        objectFit={'contain'}
        forwardSx={{
          '--slider-height': ['33.1rem', null, '50rem', '42rem', '78.6rem']
        }}
      />

      <Box
        sx={{
          px: ['var(--horizontal-inset)', null, 0]
        }}
      >
        <Heading
          as="h1"
          variant="h1"
          sx={{
            fontWeight: 'medium',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            fontSize: ['1.8rem', '2.5rem', '2rem', '3rem'],
            marginBottom: ['1rem', '2.8rem', 0, '0.8rem']
          }}
        >
          {product.name}
        </Heading>

        <Text
          as="p"
          sx={{
            marginBottom: ['0.4rem', '0.8rem', '0.4rem', '0.6rem'],
            fontSize: ['1.6rem', null, '1.5rem', '2rem']
          }}
        >
          {variant.regularPrice !== variant.price ? (
            <Fragment>
              <span sx={{ textDecoration: 'line-through', mr: '10px' }}>
                {formatMoney(variant.regularPrice, { locale })}
              </span>
              <span sx={{ color: 'red' }}>
                {formatMoney(variant.price, { locale })}
              </span>
            </Fragment>
          ) : (
            formatMoney(variant.price, { locale })
          )}
        </Text>

        {!product.externalProductPageUrl && (
          <Text
            as="div"
            sx={{
              fontSize: ['1.2rem', null, '1rem', '1.5rem'],
              mb: ['2.8rem', '3rem', '2.4rem', '3.6rem']
            }}
          >
            {/* Placement v2  */}
            <klarna-placement
              data-key="credit-promotion-small"
              data-locale="en-US"
              data-purchase-amount={`${Math.round(variant.price)}00`}
            ></klarna-placement>
            {/* end Placement  */}
          </Text>
        )}

        <Text
          as="p"
          sx={{
            fontSize: ['1.3rem', null, null, '1.5rem']
          }}
        >
          {product.shortDescription}
        </Text>

        {product.longDescription?.childMarkdownRemark && (
          <Box
            sx={{
              fontSize: ['1.3rem', null, null, '1.5rem'],
              a: { variant: 'text.link' }
            }}
            dangerouslySetInnerHTML={{
              __html: product.longDescription.childMarkdownRemark.html
            }}
          />
        )}

        <ProductAddToCart
          notices={!!variant.notices}
          variants={product.variants}
          activeVariant={activeVariant}
          product={product}
          optionValues={optionValues}
          onVariantChange={onVariantChange}
          setVariant={setVariant}
          externalLink={product.externalProductPageUrl}
          slug={product.slug}
          search={search}
        />
        {variant.notices && (
          <Flex
            sx={{
              flexDirection: 'column',
              mb: '10px',
              alignItems: 'flex-start'
            }}
          >
            {variant.notices.map(
              notice =>
                notice !== ' ' && (
                  <Text
                    key={notice}
                    sx={{
                      fontSize: '12px',
                      textAlign: 'left',
                      color: 'red',
                      ':first-of-type': {
                        mt: ['10px', '5px']
                      }
                    }}
                  >
                    {notice}
                  </Text>
                )
            )}
          </Flex>
        )}

        <ProductDetailedInformation product={product} />
      </Box>
    </Container>
  )
}

export default AccessoryHeader
