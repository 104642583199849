/** @jsx jsx */
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Button, Text, Spinner, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import useVariantAvailability from '~/hooks/components/use-variant-availability'

const AlternateProductAddToCartButton = ({
  isLoading,
  error,
  variantId,
  comingSoon
}) => {
  const translate = useTranslate()

  const {
    isFetchingAvailability,
    isAvailable,
    error: availabilityError
  } = useVariantAvailability({
    variantId
  })

  return (
    <Fragment>
      <Button
        type="submit"
        variant="primary"
        sx={{
          '--border-color': 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '32.2rem',
          fontSize: ['1.2rem', '1.3rem', null, null, '1.5rem'],
          fontWeight: 'bold',
          height: ['3.8rem', '4rem', '3.2rem', '4.4rem'],
          ':disabled': { opacity: 0.5 }
        }}
        disabled={
          isLoading || isFetchingAvailability || !isAvailable || comingSoon
        }
      >
        {(isLoading || isFetchingAvailability) && (
          <Spinner
            aria-label="Loading..."
            data-testid="spinner"
            size="15"
            color="inherit"
          />
        )}
        {comingSoon &&
          !isFetchingAvailability &&
          !isLoading &&
          translate('product.coming_soon')}
        {!isLoading && !isFetchingAvailability && !comingSoon && (
          <Fragment>
            {isAvailable
              ? translate('product.add_to_cart')
              : translate('product.sold_out')}
          </Fragment>
        )}
      </Button>

      {error && (
        <Text variant="formError" sx={{ textAlign: 'center', mt: 1 }}>
          {error?.toString()}
        </Text>
      )}

      {availabilityError && (
        <Text variant="formError" sx={{ textAlign: 'center', mt: 1 }}>
          {availabilityError?.toString()}
        </Text>
      )}
    </Fragment>
  )
}

AlternateProductAddToCartButton.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  variantId: PropTypes.number
}

AlternateProductAddToCartButton.defaultProps = {
  isLoading: false,
  error: null
}

export default AlternateProductAddToCartButton
