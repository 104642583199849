/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import { useEffect } from 'react'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

const ProductOptionSelector = ({
  type,
  options,
  selectedOptions,
  handleSelection
}) => {
  const isDesktopViewport = useMatchMedia('(min-width: 1024px)')
  useEffect(() => {
    const preventScroll = e => {
      e.preventDefault()
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
    }
    if (isDesktopViewport) document.addEventListener('scroll', preventScroll)
    return () => document.removeEventListener('scroll', preventScroll)
  }, [])

  return (
    <Flex
      sx={{
        flexFlow: type === 'values' ? 'row wrap' : 'row nowrap',
        alignItems: 'center',
        position: 'relative',
        '[data-type="color"]:checked + label': {
          borderColor: 'white',
          boxShadow: ' 0 0 0 2px black'
        }
      }}
    >
      {options.map(option => (
        <Flex key={option.slug} sx={{ position: 'relative', mx: '5px' }}>
          <input
            type="radio"
            name={type}
            value={option.slug}
            checked={
              selectedOptions.find(o => o.slug === option.slug) !== undefined
            }
            data-type={type}
            onChange={({ target: { checked } }) =>
              checked ? handleSelection(option) : null
            }
            sx={{
              clipPath: 'inset(100%)',
              clip: 'rect(1px, 1px, 1px, 1px)',
              width: '1px',
              height: '1px',
              overflow: 'hidden',
              position: 'absolute',
              whiteSpace: 'nowrap',
              ':not([data-type="color"]):checked + label': {
                color: 'white',
                backgroundColor: '#757575'
              }
            }}
            id={option.slug}
          />
          <Flex
            as="label"
            title={option.name}
            onClick={() => {
              if (typeof window !== 'undefined') {
                setTimeout(() => {
                  window.scrollTo(0, 0)
                }, 10)
              }
            }}
            htmlFor={option.slug}
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              width:
                type === 'color'
                  ? ['2.3rem', '2.7rem']
                  : type === 'values'
                  ? 'auto'
                  : 'auto',
              height: type === 'color' ? ['2.3rem', '2.7rem'] : 'auto',
              minHeight: type === 'color' ? null : ['2.8rem', '3.3rem'],
              padding:
                type === 'values'
                  ? '0 1.2rem'
                  : type === 'color'
                  ? 0
                  : '0 10px',
              fontSize:
                type === 'values' ? '1.3rem' : ['1.5rem', null, '1.7rem'],
              backgroundColor:
                type === 'color'
                  ? option.optionalRendering
                  : type === 'values'
                  ? 'transparent'
                  : 'background',
              borderRadius:
                type !== 'color' && type !== 'size'
                  ? '4px'
                  : type === 'color'
                  ? '50%'
                  : type === 'tires'
                  ? '3px'
                  : '25px',
              border: type === 'color' ? '1px solid' : 0,
              borderColor: 'rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              ':not(:last-of-type)': {
                marginRight: '0.6rem'
              }
            }}
            aria-label={`${option.name}`}
          >
            {type === 'color' ? '' : option.presentation || option.name}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

ProductOptionSelector.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      presentation: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  )
}

export default ProductOptionSelector
