import PropTypes from 'prop-types'
import React from 'react'
import { Box, Grid, Heading } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import QuickAddProductCard from '~/components/Product/QuickAddCard'

const renderGridItems = items => {
  return items.map(item => {
    switch (item.__typename) {
      case 'ContentfulProduct':
        return <QuickAddProductCard key={item.slug} product={item} />

      default:
        console.warn(`Oh snap! ${item.__typename} model is not supported yet.`)
        return null
    }
  })
}

const RelatedProducts = ({ products = [] }) => {
  const translate = useTranslate()
  return (
    <Box
      as="section"
      sx={{
        px: ['2.7rem', '3.7rem', '4.2rem', '13.2rem'],
        maxWidth: '1920px',
        margin: '9rem auto'
      }}
    >
      <Heading
        as="h2"
        variant="h2"
        sx={{
          fontSize: [2, 2, 2, 5],
          textAlign: 'left',
          textTransform: 'uppercase',
          marginBottom: '2rem',
          fontWeight: 'medium'
        }}
      >
        {translate('product.related_products')}
      </Heading>

      <Grid
        columns={[2, 3, 4]}
        gap={['0.6rem', '1rem', '1.7rem']}
        sx={{
          gridTemplateRows: '1fr'
        }}
      >
        {renderGridItems(products)}
      </Grid>
    </Box>
  )
}

RelatedProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}))
}

export default RelatedProducts
