/** @jsx jsx */
import { Button, Container, Grid, Heading, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { IoAddSharp } from 'react-icons/io5'
import VisuallyHiddenBox from '~/components/Generic/VisuallyHiddenBox/index'
import MinusIcon from '~/assets/images/icons/minus.svg'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

const ProductSpecifications = ({ specs }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const buttonRef = useRef()

  const isMobile = useMatchMedia('(max-width: 1023px)')

  if (!specs || !specs.length) return null

  return (
    <section
      sx={{
        color: 'white',
        padding: ['2.4rem 1.6rem', '2.4rem'],
        backgroundColor: 'backgroundInverted'
      }}
    >
      <VisuallyHiddenBox>
        <Heading
          as="h2"
          variant="text.h2"
          sx={{
            textTransform: 'uppercase',
            textAlign: 'center',
            color: 'inherit',
            cursor: 'pointer'
          }}
        >
          Specifications
        </Heading>
      </VisuallyHiddenBox>

      <Button
        ref={buttonRef}
        variant="buttons.clear"
        sx={{
          variant: 'text.h2',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          fontSize: [4, null, 6],
          textTransform: 'uppercase',
          textAlign: 'center',
          color: 'inherit',
          ':hover': { borderBottom: 0 }
        }}
        aria-label={isCollapsed ? `Show specifications` : `Hide specifications`}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
          if (buttonRef.current && !isMobile && isCollapsed) {
            setTimeout(() => {
              buttonRef.current.scrollIntoView()
            }, 200)
          }
        }}
      >
        Specifications
        <IoAddSharp
          sx={{
            display: isCollapsed ? 'inline-block' : 'none',
            marginLeft: '0.8rem'
          }}
        />
        <MinusIcon
          sx={{
            display: isCollapsed ? 'none' : 'inline-block',
            marginLeft: '0.8rem'
          }}
        />
      </Button>

      <Container
        as="dl"
        sx={{
          display: isCollapsed ? 'none' : 'block',
          maxWidth: '154.2rem',
          paddingTop: [2, null, 4]
        }}
      >
        {specs.map(spec => (
          <Grid
            columns={['10rem 1fr', null, '20rem 1fr', '34rem 1fr']}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'stretch',
              gap: ['1.6rem', '2.4rem'],
              padding: ['2.4rem 0', '2.4rem'],
              ':not(:last-of-type)': {
                borderBottom: '0.5px solid white'
              }
            }}
            key={spec.id}
          >
            <dt
              sx={{
                variant: 'text.small',
                fontSize: [1, null, 2, 5],
                fontWeight: 'medium'
              }}
            >
              {spec.name}
            </dt>

            <dd
              sx={{
                fontSize: [1, null, 2, 5],
                margin: 0,
                p: {
                  margin: 0
                }
              }}
              dangerouslySetInnerHTML={{
                __html: spec.description.childMarkdownRemark.html
              }}
            ></dd>
          </Grid>
        ))}
      </Container>
    </section>
  )
}

ProductSpecifications.propTypes = {
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired
      }).isRequired
    })
  )
}

export default ProductSpecifications
