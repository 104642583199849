/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, Container, Heading, Text, Box, Grid } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import formatMoney from '~/utils/formatMoney'
import { GatsbyImage } from 'gatsby-plugin-image'
import ProductDetailedInformation from '~/components/Product/DetailedInformation'
import ProductAddToCart from '~/components/Product/AddToCart'
import DragToSpin from '~/components/Generic/DragToSpin'

const ApparelHeader = ({
  product,
  activeVariant,
  onVariantChange,
  primaryImage,
  secondaryImages,
  optionValues,
  setVariant
}) => {
  const location = useLocation()
  const variant = product.variants.find(v => v.id === activeVariant)

  const collection = product.collection.find(collection =>
    location.pathname.includes(collection.slug)
  )
  const lineup = collection.lineup
  const parentPath = lineup
    ? lineup[0].lineup
      ? lineup[0].lineup[0].slug
      : lineup[0].slug
    : null

  const lookbookPath = parentPath
    ? `/${parentPath}/${collection.slug}/lookbook`
    : ''

  const allImages = [
    primaryImage,
    variant.imageSequence || null,
    ...secondaryImages
  ].filter(Boolean)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }, [variant])

  return (
    <Container
      as="header"
      variant="fullWidth"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 26rem', '1fr 40rem'],
        alignItems: [null, null, 'flex-start'],
        gap: ['2.4rem 0', '5rem 0', '0 3.6rem', '0 8.7rem'],
        px: [null, null, 'var(--horizontal-inset)']
      }}
    >
      <ImageCarousel
        enableZoom
        primaryImage={primaryImage}
        secondaryImages={secondaryImages}
        objectFit="contain"
        forwardSx={{
          '--slider-height': ['60rem', null, '108rem', '50.8rem', '93.5rem'],
          display: [null, null, 'none']
        }}
      >
        {variant.imageSequence ? (
          <DragToSpin sequence={variant.imageSequence} />
        ) : null}
      </ImageCarousel>

      <Grid
        sx={{
          display: ['none', null, 'grid'],
          gridTemplateColumns: [
            null,
            null,
            allImages.length === 1 ? null : '1fr 1fr'
          ],
          gap: [2, null, null, 4]
        }}
      >
        {allImages.map((image, index) =>
          image instanceof Array ? (
            <Box
              sx={{
                gridColumn:
                  image[0].fluid.aspectRatio < 1 ? 'span 1' : 'span 2',
                height:
                  image[0].fluid.aspectRatio < 0.65
                    ? [null, null, '25rem', '46.75rem']
                    : [null, null, '50.8rem', '93.5rem']
              }}
              key={index}
            >
              <DragToSpin sequence={image} />
            </Box>
          ) : (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image?.description ?? image?.title}
              sx={{
                gridColumn: image.fluid.aspectRatio < 1 ? 'span 1' : 'span 2',
                alignSelf: ['flex-start', null, null, null, 'stretch'],
                height:
                  image.fluid.aspectRatio < 0.65
                    ? [null, null, '25rem', '46.75rem']
                    : [null, null, '50.8rem', '93.5rem']
              }}
              imgStyle={{ objectFit: 'contain' }}
              key={index}
            />
          )
        )}
      </Grid>

      <Box
        sx={{
          px: ['var(--horizontal-inset)', null, 0],
          maxHeight: ['60rem', null, '50.8rem', '93.5rem'],
          overflowY: 'auto'
        }}
      >
        <Heading
          as="h1"
          variant="h1"
          sx={{
            fontWeight: 'medium',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            fontSize: ['1.8rem', '2.5rem', '2rem', '3rem'],
            marginBottom: ['1rem', '2.8rem', 0, '0.8rem']
          }}
        >
          {product.name}
        </Heading>
        <Text
          as="p"
          sx={{
            marginBottom: ['0.4rem', '0.8rem', '0.4rem', '0.6rem'],
            fontSize: ['1.6rem', null, '1.5rem', '2rem']
          }}
        >
          {formatMoney(variant.price)}
        </Text>

        <Text
          as="div"
          sx={{
            fontSize: ['1.2rem', null, '1rem', '1.5rem'],
            mb: ['2.8rem', '3rem', '2.4rem', '3.6rem']
          }}
        >
          {/* Placement v2  */}
          <klarna-placement
            data-key="credit-promotion-small"
            data-locale="en-US"
            data-purchase-amount={`${Math.round(variant.price)}00`}
          ></klarna-placement>
          {/* end Placement  */}
        </Text>

        <Text
          as="p"
          sx={{
            fontSize: ['1.3rem', null, null, '1.5rem'],
            marginBottom: ['3rem']
          }}
        >
          {product.shortDescription}
        </Text>

        {product.longDescription?.childMarkdownRemark && (
          <Box
            sx={{
              fontSize: ['1.3rem', null, null, '1.5rem']
            }}
            dangerouslySetInnerHTML={{
              __html: product.longDescription.childMarkdownRemark.html
            }}
          />
        )}

        <Link
          to={lookbookPath}
          sx={{
            fontSize: [3, null, 4],
            fontWeight: 'medium',
            textTransform: 'uppercase',
            textDecoration: 'underline'
          }}
        >
          View the Lookbook
        </Link>

        <ProductAddToCart
          variants={product.variants}
          product={product}
          activeVariant={activeVariant}
          optionValues={optionValues}
          onVariantChange={onVariantChange}
          forwardSx={{ p: ['3rem 0 4rem', '6rem 0', '3.5rem 0'] }}
        />

        <ProductDetailedInformation product={product} />
      </Box>
    </Container>
  )
}

export default ApparelHeader
