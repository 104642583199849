/**@jsx jsx */
import { Box, jsx } from 'theme-ui'
import { Fragment } from 'react'
import ProductPageHeader from '../Header'
import Arrow from '~/assets/images/icons/chevron-down.svg'
import ContentHero from '~/components/Content/Hero'
import ContentSection from '~/components/Content/Section'

const BikeContent = ({
  objectID,
  queryID,
  product,
  variant,
  changeVariant,
  disabledSnap,
  setVariant
}) => {
  return (
    <Fragment>
      <ProductPageHeader
        search={{ objectID, queryID }}
        product={product}
        activeVariant={variant.id}
        onVariantChange={changeVariant}
        setVariant={setVariant}
      />
      {product.features && (
        <div
          sx={{
            padding: [
              '1rem 4rem 3rem',
              '0 var(--nav-bar-height) 3rem',
              '0 var(--nav-bar-height) 5rem'
            ],
            scrollSnapAlign: 'center',
            maxWidth: '1280px',
            margin: '0 auto',
            a: { variant: 'text.link' }
          }}
          dangerouslySetInnerHTML={{
            __html: product.features.childMarkdownRemark?.html
          }}
        />
      )}
      <Box
        sx={{
          variant: 'responsive.normalLaptopUp',
          textAlign: 'center',
          position: 'relative',
          scrollSnapAlign: 'end',
          top: '-30px',
          svg: {
            width: '20px',
            height: '20px',
            animation: 'hover 1.5s ease infinite',
            path: { stroke: '#000', strokeWidth: '1' }
          }
        }}
      >
        <Arrow />
      </Box>
      {product.scrollSnapSections?.map(section =>
        section.__typename === 'ContentfulHero' ? (
          <ContentHero hero={section} key={section.id} fullScreen={false} />
        ) : (
          <ContentSection
            section={section}
            key={section.id}
            disabledSnap={disabledSnap}
          />
        )
      )}
    </Fragment>
  )
}

export default BikeContent
