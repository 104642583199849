/** @jsx jsx */
import { Box, jsx } from 'theme-ui'

const VisuallyHiddenBox = ({ children, ...props }) => (
  <Box
    sx={{
      clipPath: 'inset(100%)',
      clip: 'rect(1px, 1px, 1px, 1px)',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap'
    }}
    {...props}
  >
    {children}
  </Box>
)

export default VisuallyHiddenBox
