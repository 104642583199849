/** @jsx jsx  */
import { useEffect } from 'react'
import { jsx, Container, Grid, Text, Box, Flex } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import formatMoney from '~/utils/formatMoney'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'

const ExclusiveHeader = ({
  product,
  activeVariant,
  primaryImage,
  secondaryImages
}) => {
  const variant = product.variants.find(v => v.id === activeVariant)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }, [variant])

  return (
    <Container as="header">
      <ImageCarousel
        primaryImage={primaryImage}
        secondaryImages={secondaryImages}
        forwardSx={{
          '--slider-height': ['26.4rem', '54rem', '40rem', '74.8rem']
        }}
      />

      <Grid
        sx={{
          width: '100%',
          maxWidth: ['30rem', 'none'],
          mx: 'auto',
          gridTemplateColumns: ['100%', null, '1fr 2fr 1fr'],
          gridTemplateAreas: [
            `
              'info'
              'price'  
              'cart'
            `,
            null,
            `
              'info price cart'
            `
          ],
          gridGap: ['2.4rem'],
          alignItems: [null, null, 'center'],
          textAlign: ['left', null, 'center'],
          p: ['6rem 0', '6rem 3.6rem', '3rem 0 5rem', '7rem 0 9rem']
        }}
      >
        <Flex
          sx={{
            gridArea: 'info',
            flexFlow: 'column nowrap',
            alignItems: ['center'],
            textAlign: 'left'
          }}
        >
          <Text
            as="p"
            sx={{
              variant: 'text.h5',
              width: '100%',
              fontSize: ['2rem', '3rem', '2rem', '3rem'],
              fontWeight: 'medium',
              mb: '0.6rem',
              whiteSpace: 'wrap'
            }}
          >
            {product.name}
          </Text>

          <Text sx={{ fontSize: '1.5rem', width: '100%' }}>
            {product.shortDescription}
          </Text>
        </Flex>

        <Box
          sx={{
            '--border': [
              null,
              null,
              theme => `2px solid ${theme.colors.borderInactive}`
            ],
            gridArea: 'price',
            borderLeft: 'var(--border)',
            borderRight: 'var(--border)'
          }}
        >
          <Text
            as="p"
            sx={{
              variant: 'text.h5',
              fontWeight: 'body',
              marginBottom: ['0.4rem', '0.8rem', '0.4rem', '0.6rem'],
              fontSize: ['2.5rem', null, '2rem', '3rem']
            }}
          >
            {formatMoney(variant.price)}
          </Text>

          <Text
            as="p"
            sx={{
              fontSize: ['1.2rem', null, '1rem', '1.5rem'],
              fontWeight: 'light'
            }}
          >
            {/* Placement v2  */}
            <klarna-placement
              data-key="credit-promotion-small"
              data-locale="en-US"
              data-purchase-amount={`${Math.round(variant.price)}00`}
            ></klarna-placement>
            {/* end Placement  */}
          </Text>
        </Box>

        <Box sx={{ gridArea: 'cart', form: { margin: '0 auto' } }}>
          <ProductAddToCartButton
            comingSoon={variant.comingSoon}
            limitOnePerCustomer={variant.limitOnePerCustomer}
            sku={variant.sku}
            quantity={1}
            variantId={variant.shopifyId}
            ctaText="Add to cart"
          />
        </Box>
      </Grid>
    </Container>
  )
}

export default ExclusiveHeader
