import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from 'theme-ui'
import { Widget } from './styles'

/*
 * This components is a placeholder for the Yotpo product reviews widget.
 * See gatbsy-browser.js for the widget script.
 * It requires the Shopify ID so no reviews will be visible on the development store.
 * Sample Production Product ID - 4491448221778
 */

const ProductReviews = ({ shopifyProductId }) => {
  useEffect(() => {
    if (!shopifyProductId || !process.env.GATSBY_YOTPO_APP_KEY) return

    // The site-wide YOTPO widget script is loaded on `gatsby-browser.js` however, when the PDP loads we don't have the product id right away and so the script will fail to load the reviews. Hence when displaying product-specific reviews we'll

    // 1. Remove the previous widget script (just to avoid duplicating scripts)
    const prevScript = document.querySelector('#yotpo-widget-script')
    if (prevScript) prevScript.remove()

    // 2. Clear YOTPO's cache under `window.yotpo`
    window.yotpo = undefined

    // 3. Load the script again so it starts fresh
    const script = document.createElement('script')
    script.src = `https://staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`
    script.async = false

    document.body.appendChild(script)
  }, [shopifyProductId])

  if (!shopifyProductId || !process.env.GATSBY_YOTPO_APP_KEY) return null

  return (
    <Box
      as="section"
      sx={{
        px: ['2.7rem', '3.7rem', '9.2rem', '17.2rem'],
        maxWidth: '1920px',
        margin: '9rem auto'
      }}
    >
      <Heading
        as="h2"
        variant="h2"
        sx={{
          fontSize: [2, 2, 2, 5],
          textAlign: 'left',
          textTransform: 'uppercase',
          marginBottom: '0.9rem'
        }}
      >
        Reviews
      </Heading>

      <Widget>
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={shopifyProductId}
        />
      </Widget>
    </Box>
  )
}

ProductReviews.propTypes = {
  shopifyProductId: PropTypes.any
}

export default ProductReviews
