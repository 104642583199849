import useShopify from '~/hooks/shopify/use-shopify'

const useAllVariants = handle => {
  const query = `
    {
      productByHandle(handle: "${handle}") {
        id
        variants(first: 30) {
          edges {
            node {
              id
              availableForSale
              currentlyNotInStock
              sku
            }
          }
        }
      }
    }
  `
  const { data, isLoading, error } = useShopify(query)

  return {
    product: data?.productByHandle,
    isFetching: isLoading,
    error: error
  }
}

export default useAllVariants
