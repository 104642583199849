/** @jsx jsx  */
import { jsx, Container, Heading, Grid, Text, Box, Flex } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import ProductOptionSelector from '~/components/Product/OptionSelector/alternate'
import formatMoney from '~/utils/formatMoney'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import { Button } from '@theme-ui/components'
import { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'

const BikeHeader = ({
  product,
  search,
  activeVariant,
  onVariantChange,
  primaryImage,
  secondaryImages,
  optionValues,
  setVariant
}) => {
  const { locale } = useIntl()
  const { externalProductPageUrl, customCtaText } = product

  const variant = product.variants.find(v => v.id === activeVariant)

  const [upcs, setUpcs] = useState([])

  useEffect(() => {
    if (product.variants) {
      setUpcs(
        product.variants
          .filter(variant => !!variant.globalTradeItemNumber)
          .map(variant => variant.globalTradeItemNumber)
      )
    }
  }, [product])

  const colors = optionValues?.filter(
    ({ option_type = [] }) =>
      option_type?.length && option_type[0].slug === 'color'
  )

  const tires = optionValues?.filter(
    ({ option_type = [] }) =>
      option_type?.length && option_type[0].slug === 'Tire'
  )

  useEffect(() => {
    document.documentElement.scrollTo(0, 0)

    // Locally

    const lcly_config_0 = {
      company_name: 'Super73',
      button_text: 'IN STOCK NEAR YOU',
      button_id: 'HTML',
      company_id: '179043',
      css: '3',
      upc: variant.globalTradeItemNumber,
      show_brand_name: '1',
      n_related_styles: '3',
      show_location_switcher: '1',
      show_location_prompt: '1',
      lang: 'en-us',
      n_dealers: '3',
      no_link: '1',
      link_opens_modal: '1',
      target: 'new',
      // hide_all_when_no_stock: '1',
      radius_in_miles: '100'
    }
    const lcly_query_0 = Object.keys(lcly_config_0)
      .reduce(function (a, k) {
        a.push(
          encodeURIComponent(k) + '=' + encodeURIComponent(lcly_config_0[k])
        )
        return a
      }, [])
      .join('&')
    const lcly_endpoint_0 =
      'https://super73.locally.com/stores/map.js?' + lcly_query_0
    const scriptTag = document.createElement('script')

    scriptTag.src = lcly_endpoint_0
    document.head.appendChild(scriptTag)
  }, [product, variant])

  useEffect(() => {
    const gtin = variant.globalTradeItemNumber

    if (typeof window !== 'undefined' && window.lcly_reload_0 && gtin) {
      try {
        window.lcly_reload_0({ upc: gtin })
      } catch (error) {
        console.error(error)
      }
    }
  }, [variant])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }, [variant])

  return (
    <Container sx={{ pb: '12px !important' }}>
      <Box
        sx={{
          p: 0,
          pt: [
            'calc(var(--nav-bar-height) + 2.1rem)',
            'calc(var(--nav-bar-height) + 4.6rem)',
            'calc(var(--nav-bar-height) + 1.6rem)',
            'calc(var(--nav-bar-height) + 4rem)'
          ]
        }}
      />
      <Heading
        as="h1"
        variant="h1"
        sx={{
          fontSize: ['3rem', null, '3.5rem', '4rem'],
          fontWeight: 'medium',
          mb: ['2.2rem', '3.7rem', 0, '3rem']
        }}
      >
        {product.name}
      </Heading>

      <ImageCarousel
        enableZoom
        primaryImage={primaryImage}
        secondaryImages={secondaryImages}
        forwardSx={{
          '--slider-height': ['26.4rem', null, '54rem', '65vh', '74.8rem']
        }}
      />
      <Grid
        sx={{
          width: '100%',
          maxWidth: ['30rem', null, 'none'],
          mx: 'auto',
          gridTemplateColumns: !!tires.length
            ? ['100%', null, '1fr 1fr 2fr 1fr']
            : ['100%', null, '1fr 2fr 1fr'],
          gridTemplateAreas: !!tires.length
            ? [
                `
              'price'
              'color'
              'tire'
              'cart'
            `,
                null,
                `
              'color tire price cart'
            `
              ]
            : [
                `
              'price'
              'color'
              'cart'
            `,
                null,
                `
              'color price cart'
            `
              ],
          gridGap: ['2.4rem'],
          alignItems: [null, null, 'center'],
          textAlign: 'center',
          p: [
            '2.4rem 0',
            null,
            upcs.length > 0 ? '3rem 0 10rem' : '3rem 0 5rem',
            '7rem 0 9rem'
          ]
        }}
      >
        <Flex
          sx={{
            gridArea: 'color',
            flexFlow: 'column nowrap',
            alignItems: ['center']
          }}
        >
          <Text
            as="p"
            sx={{
              variant: 'text.h5',
              width: '100%',
              fontSize: ['1.7rem', null, '1.5rem', '2rem'],
              fontWeight: 'medium',
              mb: '0.6rem',
              whiteSpace: 'nowrap'
            }}
          >
            {variant.optionValues[0].name}
          </Text>

          <ProductOptionSelector
            type="color"
            options={colors}
            selectedOptions={variant.optionValues}
            handleSelection={option =>
              onVariantChange(variant, option, setVariant, product)
            }
          />
        </Flex>
        {!!tires.length && (
          <Flex
            sx={{
              gridArea: 'tire',
              flexFlow: 'column nowrap',
              alignItems: ['center']
            }}
          >
            <Text
              as="p"
              sx={{
                variant: 'text.h5',
                width: '100%',
                fontSize: ['1.7rem', null, '1.5rem', '2rem'],
                fontWeight: 'medium',
                mb: '0.6rem',
                whiteSpace: 'nowrap'
              }}
            >
              Tires
            </Text>
            <ProductOptionSelector
              type="tires"
              options={tires}
              selectedOptions={variant.optionValues}
              handleSelection={option =>
                onVariantChange(variant, option, setVariant, product)
              }
            />
          </Flex>
        )}

        <Box
          sx={{
            '--border': [
              null,
              null,
              theme => `2px solid ${theme.colors.borderInactive}`
            ],
            gridArea: 'price',
            borderLeft: 'var(--border)',
            borderRight: 'var(--border)'
          }}
        >
          <Text
            as="p"
            sx={{
              variant: 'text.h5',
              fontWeight: 'medium',
              marginBottom: ['0.4rem', '0.8rem', '0.4rem', '0.6rem'],
              fontSize: ['2.5rem', null, '2rem', '3rem']
            }}
          >
            {variant.regularPrice !== variant.price ? (
              <Fragment>
                <span sx={{ textDecoration: 'line-through', mr: '10px' }}>
                  {formatMoney(variant.regularPrice, { locale })}
                </span>
                <span sx={{ color: 'red' }}>
                  {formatMoney(variant.price, { locale })}
                </span>
              </Fragment>
            ) : (
              formatMoney(variant.price, { locale })
            )}
          </Text>

          <Text
            as="div"
            sx={{
              fontSize: ['1.2rem', null, '1rem', '1.5rem']
            }}
          >
            {/* Placement v2  */}
            <klarna-placement
              data-key="credit-promotion-small"
              data-locale="en-US"
              data-purchase-amount={`${Math.round(variant.price)}00`}
            ></klarna-placement>
            {/* end Placement  */}
          </Text>
        </Box>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: 'max-content',
            maxWidth: '340px'
          }}
        >
          <ProductAddToCartButton
            externalLink={externalProductPageUrl}
            customCtaText={customCtaText}
            comingSoon={variant.comingSoon}
            quantity={1}
            limitOnePerCustomer={variant.limitOnePerCustomer}
            sku={variant.sku}
            variantId={variant.shopifyId}
            search={search}
          />
          {variant.notices && (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '340px'
              }}
            >
              {variant.notices.map(
                notice =>
                  notice !== ' ' && (
                    <Text
                      key={notice}
                      sx={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'black',
                        ':first-of-type': {
                          mt: '5px'
                        }
                      }}
                    >
                      {notice}
                    </Text>
                  )
              )}
            </Flex>
          )}
          <Text
            sx={{
              zIndex: 1,
              fontSize: '11px',
              textAlign: ['center'],
              mt: ['12px', 0, 0],
              width: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            California residents{' '}
            <Button
              variant="clear"
              sx={{
                ml: '2px',
                borderBottom: 'none !important',
                textDecoration: 'none',
                ':hover': {
                  textDecoration: 'underline'
                },
                ':focus + #warning, :active + #warning': {
                  opacity: 1,
                  pointerEvents: 'auto'
                }
              }}
            >
              see Prop 65 WARNINGS
            </Button>
            <Text
              id="warning"
              sx={{
                fontSize: 2,
                position: 'absolute',
                background: 'rgba(0,0,0,0.8)',
                color: 'white',
                padding: '10px 15px',
                whiteSpace: [null, null, 'nowrap'],
                right: [null, 0],
                opacity: 0,
                pointerEvents: 'none',
                transition: 'opacity 0.25s ease'
              }}
            >
              Warning: Cancer and Reproductive Harm -{' '}
              <a
                href="https://www.p65warnings.ca.gov/"
                target="__blank"
                rel="noopener noreferrer"
              >
                www.p65warnings.ca.gov
              </a>
            </Text>
          </Text>
          {/* LOCALLY.COM WIDGET EMBED CODE */}

          {upcs !== null && (
            <Box
              sx={{
                position: 'relative',

                'a.lcly-primary-trigger span': {
                  fontSize: ['12px', '13px', null, '15px'],
                  fontWeight: 'bold',
                  borderRadius: '6px',
                  border: '1px solid black',
                  transition: '0.2s ease',
                  height: ['33px', '40px', '44px'],
                  lineHeight: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: ['0px 22px', '0px 22px', '4px 5px'],
                  color: 'white !important',
                  backgroundColor: 'black !important'
                },
                left: 0,
                width: '100%',
                maxWidth: '34rem',
                '.lcly-brand-name': { display: 'none' }
              }}
            >
              <div
                id="lcly-button-0"
                data-switchlive="true"
                data-switchlive-impression="true"
                data-switchlive-impression-id-pl="1"
              >
                <a
                  id="lcly-link-0"
                  data-switchlive="true"
                  data-switchlive-mode="auto"
                  data-switchlive-id-pl="6"
                  href="https://brands.locally.com"
                  target="_blank"
                >
                  Product Locator by Locally
                </a>
              </div>
            </Box>
          )}
        </Box>
      </Grid>
    </Container>
  )
}

export default BikeHeader
