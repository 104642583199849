/** @jsx jsx */
import { jsx } from 'theme-ui'
import filterDuplicates from '~/utils/filter-duplicates'
import AccessoryHeader from './Accessory'
import ApparelHeader from './Apparel'
import BikeHeader from './Bike'
import ExclusiveHeader from './Exclusive'

const ProductPageHeader = ({
  product,
  activeVariant,
  onVariantChange,
  setVariant,
  search
}) => {
  const type = product.productType?.slug
  const variants = product.variants
  const variant = variants.find(v => v.id === activeVariant)

  // primary image is the variant main image, using product main image as fallback
  const primaryImage = variant.mainImage ?? product.mainImage ?? []

  // combine additional product and variant images
  const variantImages = variant.images ?? []

  // dedupe identical additional images
  const additionalImages = filterDuplicates([...variantImages], 'id')

  // finally, just in case, exclude the primaryImage from additional images
  const secondaryImages = additionalImages.filter(
    img => img.id !== primaryImage.id
  )

  // Option values available across variants
  const availableOptionValues = filterDuplicates(
    product.variants
      .filter(v => !v.hideEverywhere)
      .reduce(
        (acc, { optionValues }) =>
          optionValues ? [...acc, ...optionValues] : acc,
        []
      ),
    'slug'
  )

  const props = {
    product,
    search,
    activeVariant,
    primaryImage,
    secondaryImages,
    optionValues: availableOptionValues,
    onVariantChange,
    setVariant
  }

  if (product.exclusive) return <ExclusiveHeader {...props} />

  if (type === 'electric-motorbike')
    return <BikeHeader {...props} secondaryImages={variantImages} />

  if (
    ['accessories', 'part', 'gift-card', 'merchandise', 'apparel'].includes(
      type
    )
  )
    return <AccessoryHeader {...props} />

  if ([].includes(type)) return <ApparelHeader {...props} />

  return null
}

export default ProductPageHeader
